import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider } from '@/UI';
import {
  COLOR_MODE,
  COLORS,
  getAntdTheme,
  INITIAL_COLOR_MODE,
  ThemeDisplayName,
} from '@/configs/themeConfig';
import { theme as antdTheme } from 'antd';
import vn from 'antd/locale/vi_VN';
import { flushSync } from 'react-dom';

interface IThemeContext {
  ref: any;
  theme: ThemeDisplayName;
  // eslint-disable-next-line no-unused-vars
  toggleTheme: (theme?: ThemeDisplayName) => void;
}

function getAllColors(theme: ThemeDisplayName) {
  const themeColors = Object.entries(COLORS)?.reduce((acc, [colorName, colorValues]) => {
    acc[colorName] = colorValues?.[theme];
    return acc;
  }, {} as Record<string, string>);

  return themeColors;
}

const disableTransition = () => {
  const css = document.createElement('style');
  css.appendChild(
    document.createTextNode(
      // eslint-disable-next-line max-len
      '*,*::before,*::after{-webkit-transition:none!important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important}'
    )
  );
  document.head.appendChild(css);

  return () => {
    // Force restyle
    (() => window.getComputedStyle(document.body))();

    // Wait for next tick before removing
    setTimeout(() => {
      document.head.removeChild(css);
    }, 1);
  };
};

const resetTheme = (newTheme, setTheme) => {
  const root = document.documentElement;
  setTheme(newTheme);
  Object.entries(COLORS).forEach(([name, colorByTheme]) =>
    root.style.setProperty(`--color-${name}`, colorByTheme[newTheme])
  );
  if (newTheme === 'dark') root.classList.add(newTheme);
  else root.classList.remove('dark');
};

const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState<ThemeDisplayName>('light');
  const ref = useRef(null);

  useEffect(() => {
    const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue(INITIAL_COLOR_MODE);

    setTheme(initialColorValue as ThemeDisplayName);
  }, []);

  const toggleTheme = useCallback(
    async (newTheme?: ThemeDisplayName) => {
      if (!newTheme) newTheme = theme === 'dark' ? 'light' : 'dark';

      localStorage.setItem(COLOR_MODE, newTheme);

      if (
        !ref.current ||
        !document.startViewTransition ||
        window.matchMedia('(prefers-reduced-motion: reduce)').matches
      ) {
        disableTransition()();

        resetTheme(newTheme, setTheme);
        return;
      }

      await document.startViewTransition(() => flushSync(() => resetTheme(newTheme, setTheme))).ready;

      const { top, left, width, height } = ref.current.getBoundingClientRect();
      const x = left + width / 2;
      const y = top + height / 2;
      const right = window.innerWidth - left;
      const bottom = window.innerHeight - top;
      const maxRadius = Math.hypot(Math.max(left, right), Math.max(top, bottom));

      document.documentElement.animate(
        {
          clipPath: [`circle(0px at ${x}px ${y}px)`, `circle(${maxRadius}px at ${x}px ${y}px)`],
        },
        {
          duration: 500,
          easing: 'ease-in-out',
          pseudoElement: '::view-transition-new(root)',
        }
      );
    },
    [theme]
  );
  const contextValue = useMemo(() => ({ toggleTheme, theme, ref }), [theme, toggleTheme]);

  const colors = getAllColors(theme);

  const customAntdTheme = theme ? getAntdTheme(colors) : {};
  return (
    <ThemeContext.Provider value={contextValue}>
      <ConfigProvider
        theme={{ ...customAntdTheme, algorithm: theme === 'dark' && antdTheme.darkAlgorithm }}
        locale={vn}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const data = React.useContext(ThemeContext);

  if (!data) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return data;
};
