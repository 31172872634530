/* eslint-disable no-case-declarations */
import axios, { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import RequestObserver, { CustomConfig, REFRESH_URL } from './RequestObserver';
import { PUBLIC_ROUTER } from './Auth/constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSONbig = require('json-bigint');

const JSONbigString = JSONbig({ storeAsString: true });
const errorMessages = 'Có lỗi trong quá trình thực thi';
const observer = new RequestObserver();

const errorCallback = (status: number, error: string) => ({
  status: status ?? 500,
  error: error ?? errorMessages,
});

const handlePushToLogin = async () => {
  window.location.href = '/login';
};

const handle403Status = async (dataError, config) => {
  if (config.url === REFRESH_URL && !PUBLIC_ROUTER.includes(window.location.pathname)) {
    handlePushToLogin();
  }
  return errorCallback(403, dataError?.message);
};

class ApiClient {
  private readonly api: AxiosInstance;

  constructor(baseURL: string, headers = {}) {
    this.api = axios.create({
      baseURL: baseURL || '',
      timeout: 30000,
      transformResponse: [(data) => JSONbigString.parse(data)],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.api.interceptors.request.use(
      (config) => this.handleInterceptReq(config, headers),
      (error) => Promise.reject(error)
    );
    this.api.interceptors.response.use(this.handleInterceptRes, this.handleInterceptResError);
  }

  handleInterceptReq = async (config: CustomConfig, headers) => {
    const signal = await observer.getReqSignal(config);

    const token = localStorage.getItem('token') ?? '';

    config.headers.Authorization = `Bearer ${token}`;
    config.headers['app-version'] = process.env.buildId;
    config.headers = { ...config.headers, ...headers };
    config.signal = signal;

    return config;
  };

  handleInterceptRes = ({ data }: AxiosResponse) =>
    data.success === false ? { ...data, status: 400, error: data?.message ?? errorMessages } : data;

  handleInterceptResError = async (error: AxiosError) => {
    const config: CustomConfig = error.config;
    const resError = error.response;
    const dataError: any = resError?.data;
    const isCancelNotFromUser = axios.isCancel(error) && observer.getSuspendedStatus();

    if (config?._retry) return errorCallback(resError?.status, dataError?.message);

    if (resError?.status === 401 || isCancelNotFromUser) {
      config._retry = true;

      return this.api(config);
    }

    if (resError?.status === 403) {
      return handle403Status(dataError, config);
    }

    return errorCallback(resError?.status, dataError?.message);
  };

  public getInstance = () => this.api;
}

export default ApiClient;
