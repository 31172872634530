export const PUBLIC_ROUTER = [
  '/login',
  '/signup',
  '/signup/infor',
  '/signup/pageview',
  '/login-3rd-party',
  '/api/auth/callback',
  '/callback',
];
export const PRIVATE_ROUTER = ['/'];
