import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ErrorFallback } from './components/ErrorFallback';
import { Spin } from '@/UI';
import { IUserInfo } from '../interfaces';
import { APIResponse } from '@/interfaces';
import { PRIVATE_ROUTER, PUBLIC_ROUTER } from '../constants';
import { getSession } from '@/services/Auth.service';

interface Props {
  children: React.ReactNode;
}

interface IAuthContext {
  handleLogOut: () => void;
  userInfo: IUserInfo;
}

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { error, error_description } = router.query;
  const pathname = router.pathname;
  const [userInfo, setUserInfo] = useState<IUserInfo | null>({});

  useEffect(() => {
    const handleGetSession = async () => {
      try {
        const res: APIResponse = await getSession();

        if (res.error && !PUBLIC_ROUTER.includes(pathname)) {
          localStorage.removeItem('access_token');
          window.location.href = '/login';
        }

        const { access_token } = res?.data || {};

        if (access_token) {
          localStorage.setItem('token', access_token);
          setIsAuthenticated(true);
          if (PUBLIC_ROUTER.includes(pathname)) window.location.href = '/';
          return;
        }
      } catch (error) {
        if (PUBLIC_ROUTER.includes(pathname)) return;
        localStorage.removeItem('access_token');
        window.location.href = '/login';
      }
    };

    if (PUBLIC_ROUTER.includes(pathname) && isAuthenticated) {
      router.push('/');
      return;
    }

    if (!isAuthenticated) handleGetSession();
  }, [pathname]);

  const handleLogOut = () => {
    if (typeof window === 'undefined') return;
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/api/auth/logout`;
  };

  const authContextValue = useMemo(() => ({ handleLogOut, userInfo }), [userInfo]);

  if (PRIVATE_ROUTER.includes(pathname) && !isAuthenticated)
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    );

  if (error) return <ErrorFallback error={error} error_description={error_description} />;

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
