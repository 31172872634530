import ApiClient from '@/configs/ApiClient';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const IAM_URL = process.env.NEXT_PUBLIC_GAM_GATEWAY;

const api = new ApiClient(`${APP_URL}/api/auth`).getInstance();
const iamApi = new ApiClient(`${IAM_URL}/iam/api/v1`, 'authorization').getInstance();
const publicIamApi = new ApiClient(`${IAM_URL}/iam/api/v1`).getInstance();

export const endpoints = {
  login: '/login',
  loginWith3rdParty: 'login-with-3rd-party',
  callback: '/callback',
  session: '/session',
  logout: '/logout',
  refresh_token: '/refresh_token',
  sending_opt: '/users/login/otp/send',
  forgot_password: '/users/forgot-password',
  user_exist: 'users/exists',
  users_profile: 'users/profile',
  users_password: 'users/password',
};

interface ILogin {
  otp?: string;
  identity?: string;
  password?: string;
  type: 'basic' | 'otp';
}

export const login = (params: ILogin) => {
  return api.post(endpoints.login, params);
};

export const getSession = () => {
  return api.get(endpoints.session);
};

export const loginWith3rdParty = (params: { type: string }) => {
  return api.post(endpoints.loginWith3rdParty, params);
};

export const loginGoogleFirstTime = (params) => {
  return api.get(endpoints.callback, { params });
};

export const sendOtp = (params: { phone_number?: string; email?: string }) => {
  return publicIamApi.post(endpoints.sending_opt, params);
};

export const sendOtpEmail = (params: { email: string }) => {
  return publicIamApi.post(endpoints.forgot_password, params);
};

export const checkUserExist = (params: { identity: string }) => {
  return publicIamApi.get(endpoints.user_exist, { params });
};

export const getUserProfile = () => {
  return iamApi.get(endpoints.users_profile);
};

export const updatePassword = (params: { new_password: string }) => {
  return iamApi.put(endpoints.users_password, params);
};
